footer {

  display: block;
  float: left;
  position: relative;
  width: 100%;
  @media ( min-width: @screen-lg-min) {
    .logo-container {
      //width: auto;
    }
  }

  @media ( max-width: @screen-md-min) {
    .logo-container {
      text-align: center;
      float: left;
      width: 100%;
    }
  }

  img.inline-logo {
    width: 100%;
    max-width: 250px;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
  }

  ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

    li {


      a {
        //font-family: @headings-font-family;
        //font-weight: @headings-font-weight;
        margin-bottom: .5em;
        color: @brand-secondary;
      }

      li {
        margin-bottom: .75em;

        //padding-left: 1em;
        a {
          margin-bottom: 0px;
          font-size: .875em;
          font-family: @font-family-base;
          font-weight: @font-weight-base;
          //color: @brand-text-on-dark;
        }

        &:before {
          margin-left: -1em;
          content: '\25A0';
          display: inline-block;
          display: none;
          //color: @brand-secondary !important;
          width: 1em;
          margin-top: -0.125em;
          float: left;
        }

        font-family: @font-family-base;
        padding-left: 1em;

        li {
          padding-left: 2em;
        }
      }

      a {

        //color: @brand-text-on-dark !important;
        display: inline-block;
        width: 100%;
        padding: 0em;

        &:hover {
          //color: @brand-gray-light !important;
          text-decoration: none;
        }
      }

      i {
        margin-top: .25em;
        color: @brand-secondary;

        &:before {
          color: @brand-secondary;
        }

        display: inline-block;
        width: 1.25em;
      }

      a {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: flex-start;

        span {
          display: inline-block;
        }
      }

      ul {
        margin-bottom: 0px;
        font-size: 1em;
        text-transform: none;

        li {
          font-family: @font-family-base;
          font-weight: @font-weight-base;
          margin-bottom: 0em;

          a {
            font-weight: inherit;;
          }


        }
      }
    }
  }

  h4, h5 {
    text-transform: uppercase;
  }

  a.social-link i, a.social-link i.fa {
    color: @brand-secondary !important;;
  }


  .widget_nav_menu {
    font-size: 1.125em;
    text-transform: uppercase;

    > div > ul > li {
      > a {
        font-weight: @headings-font-weight;
        text-transform: uppercase;
        margin-bottom: .75em;
      }

      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul li > ul > li {
      padding-left: 0px;

      a {

      }
    }

  }

  table {
    th {
      padding-right: 1em;
    }
  }

  .row {
    @media (max-width: @screen-md-min) {
      padding-bottom: 2em;
      //text-align: center;
      h1, h2, h3, h4, ul {
        text-align: left;
      }

      &.footer-widget-area {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > div {
          width: 33%;
        }
      }
    }
    @media (max-width: @screen-sm-min) {


      &.footer-widget-area {
        > div {
          margin-bottom: 1em;
          width: 300px;
        }
      }
    }
  }

  .after-footer {
    ul.menu {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      li {
        width: auto;
        padding: 0 0 0 0;

        a {
          padding: .25em .5em;
          margin: 0 0 0 0;

        }
      }
    }
  }
  #copyright {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: .75em;
    padding-top: 1em;
    padding-bottom: 1em;
    p {
      width: auto;
      display: inline-block;
    }
    ul.menu {

      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;

      li {
        a {
          padding: 0 0 0 0;
        }
        span.menu-text {
          padding-left: 1em;
          margin-left: 1em;
          display: inline-block;
          border-left: 1px solid @brand-text-on-dark;
          width: auto;
        }

      }

    }
  }
}
