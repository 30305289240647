.testimonials-slider {
  .swiper-button-next, .swiper-button-prev {
    color: @brand-primary !important;
  }
  .testimonial-slide {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .text-object {
      max-width: calc(100% - 100px);
      padding: (@grid-gutter-width /2 );
      background: @brand-text-on-dark;
      .testimonial-text {
        font-size: 1.25em;
        line-height: @line-height-base;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        &:before,&:after{
          content: '"';
          font-size: 20px;
          color: @brand-secondary;
          display: inline-block;
          width: 1em;
          height: 1em;
          position: absolute;
          font-family: FontAwesome;
          top: 0;

        }
        &:before {
          content: '\f10e';
          transform: rotateY(180deg);
          float: left;
          left:  0px;
        }
        &:after {
          content: '\f10e';
          right: 0px;
          float: right;
        }
      }

      .testimonial-person {
        .testimonial-image {
          width: 100px;
          text-align: center;
          border-radius: 100%;
          height: 100px;
          display: inline-block;
          overflow: hidden;
          img {
            width:120px;
          }
        }
        span {
          width: 100%;
          display: inline-block;
        }
        .name {
          font-weight: @strong-weight;
        }
        .title {
           font-style: italic;
        }
        .company{
          opacity: .8;
        }
      }


    }
  }
}
.showcases-slider {
  .swiper-button-next, .swiper-button-prev {
    color: @brand-primary !important;
  }
  .showcase-slide{
    text-align:center;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .showcase-slide-content {
      background: @brand-text-on-dark;
      max-width: calc(100% - 100px);
    }
    .showcase-image {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;

      img {
        left: 0;
        width: 100%;
        height: 100%;
        max-width: none;
        object-fit: cover;
        object-position: center center;
        position: absolute;
      }
    }
    .showcase-description {
      width: 100%;
      padding: (@grid-gutter-width/2);

    }
  }
}
