.woocommerce a.button.add_to_cart_button,
.woocommerce button.button.alt,
.woocommerce button.button,
.woocommerce a.button,
.woocommerce a.button.alt,
.woocommerce a.button.wc-forward,
checkout-button.button.alt.wc-forward {
  .btn();
  .btn-primary();
}

section#main.woo-main {
  padding-top: 0px;
}

.woocommerce div.product p.price, .woocommerce div.product span.price {
  .woocommerce-Price-amount.amount {
  }
}

.woocommerce-notices-wrapper + .breadcrumb-container {
  margin-top: (@grid-gutter-width/2);
}

.woocommerce-notices-wrapper:empty + .breadcrumb-container {
  margin-top: 0;
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  margin-bottom: 0px;
}

.single #sidebar-container section.woo-main {
  > .container.breadcrumb-container {
    padding: 0 0 0 0;
    width: 100%;
    max-width: 100%;
    padding-bottom: (@grid-gutter-width/2);
  }
}

.woocommerce-error, .woocommerce-info, .woocommerce-message {
  //padding: (@grid-gutter-width/2);
  margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
  width: 100%;
  //display: inline-flex;
  //flex-wrap: wrap;
  //align-items: center;
  background: @brand-gray-light;
}

.woocommerce-error {
  border-top: .5em solid @brand-primary;

  &:before {
    color: @brand-primary;
  }
}

.woocommerce-message {
  border-top: .5em solid @brand-black;

  &:before {
    color: @brand-black;
  }
}

.woocommerce-info {
  border-top: .5em solid @brand-tertiary;

  &:before {
    color: @brand-tertiary;
  }
}

.woocommerce .filter-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  p.woocommerce-result-count {
    display: inline-block;
    font-size: 1em;
    line-height: 1em;
    margin: 0 1em 0 0;
    width: auto;
    color: @brand-gray;
  }

  form.woocommerce-ordering {
    width: auto;
    display: inline-block;
  }
}

div.product-row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  div.product.product-grid-item {
    display: block;
    box-sizing: border-box;
    background: @brand-text-on-dark;
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    border: (@grid-gutter-width* .125) solid @brand-gray-light;

    .woocommerce-LoopProduct-link.woocommerce-loop-product__link {
      display: inline-block;
      width: 100%;


      .category-image-wrap {
        display: inline-block;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        border-bottom: 1px solid @brand-gray-light;

        img {
          position: absolute;
          width: 80%;
          height: 80%;
          max-width: none;
          object-fit: contain;
          object-position: center center;
          left: 10%;
          top: 10%;
        }
      }

      .category-content-wrap {
        padding: (@grid-gutter-width * 0.375);
      }
    }

    a.button {
      margin-top: (@grid-gutter-width/4 * 0);
      background: @brand-text-on-dark;
      color: @brand-primary;
      width: 100%;
      text-align: center;
      border-top: 1px solid @brand-gray-light;
      padding: (@grid-gutter-width/4);

      &:hover {
        border-top: 1px solid @brand-primary;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }
    }

    h2.woocommerce-loop-product__title {
      color: @brand-black;
      font-size: 1.25em;
      line-height: @headings-line-height;
      font-weight: @headings-font-weight;
      width: 100%;
      margin-top: (@grid-gutter-width / 4);
      margin-bottom: (@grid-gutter-width /4);
    }

    span.price {
      width: 100%;
      display: inline-block;
      font-size: 1em;
      color: @brand-black;
      font-family: @headings-font-family;
      line-height: @headings-line-height;

      span.amount {
        font-size: 1em;
        color: @brand-black;
      }
    }

    width: 50%;
    @media (min-width: @screen-sm-min) {
      width: (100% / 2);
    }
    @media (min-width: @screen-md-min) {
      width: (100% / 3);
    }
    @media (min-width: @screen-lg-min) {
      width: (100% / 4);
      max-width: 320px;
    }
    @media (min-width: @screen-xl-min) {
      width: (100% / 5);
    }
  }
}


.page-template-page-landing div.product-row {
  //padding-top: (@grid-gutter-width * .5);
  justify-content: center;

  div.product.product-grid-item {
    text-align: center;
    border: 1px solid transparent;
  }
}


// woocommerce tabs handling

.woocommerce #live-area section.woo-main div.product {
  .woocommerce-tabs {
    width: 100%;
    .panel {
      //margin-top: (@grid-gutter-width/2);
      margin-bottom: (@grid-gutter-width/2);
      width: 100%;
      background: @brand-text-on-dark;
      padding: (@grid-gutter-width/2);
    }

    ul.tabs.wc-tabs {
      margin-bottom: 0;
    }
  }

  ul.tabs li {
    border-radius: 0px;
    background: @brand-gray-light;

    &.active {
      background: @brand-text-on-dark;
    }
  }
}

.woocommerce div.product .woocommerce-tabs .panel {
}

.woocommerce section.woo-main {
  div.login-notification {
    padding: (@grid-gutter-width/4);
    margin-top: (@grid-gutter-width/4);
    background: @brand-gray-light;
    color: @brand-black;

    strong {
      margin-right: 1em;
    }

    ~ #yith-wcact-form-bid {
      display: none;
    }

    ~ button#yith-wcact-auction-add-to-cart-button {
      display: none;
    }
  }
}

#sidebar-woo {
  margin-bottom: (@grid-gutter-width/2);
  @media (min-width: @screen-sm-min) {
    margin-top: 45px;
    padding-right: 0;
    padding-left: 0;
  }

  .product-categories .cat-item {
    display: none;
    width: 100%;
  }

  ul {
    width: 100%;
    list-style: none;
    padding-left: 0;
  }

  .product-categories .cat-parent {
    display: inline-flex !important;
  }

  .product-categories .cat-parent > .cat-item {
    display: inline-flex !important;
  }

  .product-categories .current-cat .cat-item {
    display: inline-flex !important
  }

  .product-categories {
    .cat-item {
      align-items: stretch;
      flex-wrap: wrap;

      a:before {
        font-family: FontAwesome;
        content: '\f10c';
        font-weight: 300;
        margin-right: .25em;
        color: @brand-gray-light;

      }

      &.current-cat > a:before {
        color: @brand-gray;
        content: '\f192';
      }

      a {
        color: @brand-black;

        span.count {
          color: @brand-gray;
        }
      }
    }

    .children {
      padding-left: .5em;
    }

  }

  .wc-brand-list-layered-nav-product_brand {
    padding-left: 0;

    li {
      &:before {
        font-family: FontAwesome;
        content: '\f10c';
        font-weight: 300;
        margin-right: .25em;
        color: @brand-gray-light;
      }

      &.chosen:before {
        color: @brand-primary;
        content: '\f192';

      }

      a {
        color: @brand-black;

        span.count {
          color: @brand-gray;
        }
      }
    }
  }

  ul li {
    margin-bottom: .25em;

    ul {
      margin-top: .25em;
    }
  }

  .woo-sidebar-widget {
    //padding: (@grid-gutter-width/4);
    background: #fff;
    margin-bottom: (@grid-gutter-width/2);
    margin-bottom: 0;

    &.widget_price_filter .accordion.active .accordion_content {
      padding-top: (@grid-gutter-width/2);

    }

    .accordion {
      width: 100%;
      margin: 0 auto;
      overflow: hidden;

      .accordion_content {
        height: 0;
        visibility: hidden;
      }

      &.active {
        .accordion_arrow {
          i.fa {
            transform: rotate(180deg);
          }
        }

        .accordion_content {
          visibility: visible;
          padding: (@grid-gutter-width/4);

          @media (min-width: @screen-sm-min) {
            padding-left: (@grid-gutter-width / 2);
          }
          height: auto;
        }
      }
    }

    h4.accordion_tab {

      position: relative;
      user-select: none;
      cursor: pointer;
      position: relative;
      padding: (@grid-gutter-width/4);
      border-top: @brand-gray-light solid 1px;
      border-bottom: @brand-gray-light solid 1px;
      @media (min-width: @screen-sm-min) {
        padding-left: (@grid-gutter-width / 2);
      }
      width: 100%;
      margin-bottom: 0;

    }

    .accordion_arrow {
      position: absolute;
      top: calc(50% - 5px);
      transform: translateY(-50%);
      right: (@grid-gutter-width /4);
      width: 10px;
      height: 10px;

      i.fa {
        tansition: transform @transition-time-global;
      }
    }
  }
}

section#main.woo-main {
  div.woocommerce-product-gallery {
    background: @brand-text-on-dark;

    ol {
      margin-bottom: 0;

    }
  }

  div.summary.entry-summary {
    padding: (@grid-gutter-width);
    background: @brand-text-on-dark;
  }
}


body.facilities-aisle {
  .home-only {
    display: none !important;
  }
}

body.home-aisle {
  .facilities-only {
    display: none !important;
  }
}

#live-area .woocommerce-products-header {
  .header-row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

  }

  .title-column {
    order: 1;
    display: flex;
    align-items: center;

    h1 {
      span.aisle {
        width: 100%;
        display: inline-block;
        font-size: 0.375em;
        line-height: 2.5;
      }
    }

    @media (min-width: @screen-md-min) {
      justify-content: center;

      .column {
        width: 100%;

        &.main-category {

          @media (min-width: @screen-lg-min) {
            max-width: 640px;
          }
        }

        padding: (@grid-gutter-width/2) 0;
      }
    }
  }

  .image-column {
    order: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 0;

    &.brand-image {
      padding: @grid-gutter-width;
      background: @brand-text-on-dark;

      img {
        width: auto;
        height: auto;


      }
    }

    @media (min-width: @screen-md-min) {
      @media (min-width: @screen-lg-min) {
        max-height: 640px;
        overflow: hidden;
      }
    }
    @media (max-width: @screen-md-min) {
      order: 0;
    }
  }
}

#live-area .woocommerce {

  .woocommerce-cart-form {
    background: @brand-text-on-dark;
  }

  .shop_table {
    background: @brand-text-on-dark;
  }

  .woocommerce-checkout.checkout {
    padding: (@grid-gutter-width /2);
    background: @brand-text-on-dark;
  }
}
