//  Default Menu Occlusion
//---------------------------------------------------------

@media (max-width: @screen-md-min) {
  #main-nav #navbar {
    display: none !important;
  }

  #main-nav {
    padding-top: (@grid-gutter-width/ 4);
    padding-bottom: (@grid-gutter-width/ 4);
    background: @main-nav-background;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 301;

    > .container {
      width: 100%;
      max-width: 100%;
      //padding-left: (@grid-gutter-width /4);
      //padding-right: (@grid-gutter-width /4);
    }
  }
}

@media (min-width: @screen-md-min) {
  .mobile-only {
    display: none !important;
  }
}

#mobile-menu-container, #mobile-trigger {
  @media (min-width: @screen-md-min) {
    display: none !important;
  }
}

//  Trigger button styling | onClick Event Attached
//---------------------------------------------------------
@menu-bar-height: 2px;
#mobile-trigger {
  position: relative;
  //top: (@grid-gutter-width/ 4);
  //right: (@grid-gutter-width / 4);
  font-family: @headings-font-family;
  overflow: hidden;
  white-space: nowrap;
  color: @main-nav-color;
  background: @main-nav-background;
  height: 50px;
  width: 50px;
  padding: .6em .5em !important;
  z-index: 301;
  transform: translateZ(0);

  // align button to bootstrap margin when container is not 100% wide

  &.open {
    // open class for animation of button context
  }

  #x-container {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
  }
}

@bar-container-height: (@logo-mobile-height - 20px);
#mobile-trigger span {
  display: block;
  position: absolute;
  height: @menu-bar-height;
  width: 100%;
  background: linear-gradient(90deg, @brand-secondary, @brand-primary);
  border-radius: 0px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

#mobile-trigger span:nth-child(1) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * 4));
}

#mobile-trigger span:nth-child(2), #mobile-trigger span:nth-child(3) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * .5));
}

#mobile-trigger span:nth-child(4) {
  top: ((@bar-container-height / 2 ) + (@menu-bar-height * 3));
}

#mobile-trigger.open span:nth-child(1) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * .5));
  width: 0%;
  left: 50%;
}

#mobile-trigger.open span:nth-child(2) {
  transform: rotate(45deg);
}

#mobile-trigger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#mobile-trigger.open span:nth-child(4) {
  top: ((@bar-container-height / 2 ) - (@menu-bar-height * .5));
  width: 0%;
  left: 50%;
}

//  Scroll Busting | scroll lock div is overlay
//---------------------------------------------------------

#scrollLock {
  display: block;
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 200;
  top: 0;
  left: 0;
  background: @scrollLockColor;
  opacity: .0;
  visibility: hidden;
  transition: opacity @transition-time-global;

  &.scrollLock {
    opacity: 0;
    height: 100%;
    visibility: visible;
  }
}


body.scrollLock {
  overflow: hidden;
  min-height: 5000%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0px;

  #wrap {
    #page-header, #header-content, #page-container, div.row.bf-cta, footer, #copyright {
      //-webkit-filter: blur(5px);
      //-moz-filter: blur(5px);
      //-ms-filter: blur(5px);
      //-o-filter: blur(5px);
      //filter: blur(5px);
    }
  }
}

//  MENU OBJECT
//---------------------------------------------------------

.mobile-menu {
  font-family: @headings-font-family;
  display: block;
  width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 300;
  height: 0;
  transition: transform @transition-time-global, right @transition-time-global;
  font-size: 1em;
  background: @brand-secondary;
  transform: translateZ(0);

  &.open {
    top: 0;
    //border-top: 1px solid @brand-gray-light;
    height: 100%;
  }

  //  Nav Object
  //---------------------------------------------------------
  nav#mobile-menu-nav {
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    ul.menu {
      list-style: none;
      margin: 0px 0px 0px 0px;
      padding: 0px;

      > li {
        border-top: 1px solid @brand-gray-light;
      }
      > li {
       > a.menu-item {
          width: calc(100% - 50px);
        }

        > a.menu-toggle {
          text-align:center;
          padding-left: 0;
          padding-right: 0;
          width: 50px;
          background: @brand-primary;
          transition: transform @transition-time-global;
          &.open {
            transform: scale(1,-1);
          }
        }
      }
      li {
        position: relative;
        padding: 0 0 0 0;
        &.normal {
          position: relative;
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          width: 100%;
        }
        ul {
          width: 100%;
          padding: 0;
          &.menu-toggle-item {
            max-height: 0px;
            overflow:hidden;
            &.open {
              max-height: 5000px;
            }
            li {
              border-top: 1px solid @brand-text-on-dark;

              a {
                width: 100%;
              }
            }

          }
          li {
            width: 100%;
          }
        }
      }

      a {
        color: @brand-text-on-dark;
        padding-top: @mobile-item-padding-vert;
        padding-left: @mobile-item-padding-horz;
        padding-right: @mobile-item-padding-horz;
        padding-bottom: @mobile-item-padding-vert;
        line-height: 1em;

        display: inline-block;
        vertical-align: bottom;
        font-weight: @main-nav-fweight;
        font-family: @main-nav-family;

        i {
          &.fa {
            font-size: 1em;
          }
        }

      }

      ul.menu-toggle-item {
      }
    }
  }

  // END NAV OBJECT

  //  Inner Trigger Objects
  //---------------------------------------------------------

  a.submenulink {
    display: inline-block;
    color: @main-nav-color;
    background: @main-nav-background;
    width: 100%;
    padding: @mobile-item-padding-vert 0;
    box-sizing: border-box;

    i {
      margin-left: 0px;
      margin-right: 0px;
    }

  }

}

#mobile-menu-nav > ul.menu > li {
  position: relative;
}


#top-nav {
  @media ( max-width: @screen-xs-max) {

  }
  @media ( max-width: @screen-sm-max) {
    display: none;
  }
}

@media (max-width: @screen-sm-max) {

  body.logged-in {
    top: 46px;

    &.scrollLock {
      top: 0px !important;

      #wpadminbar {
        //display: none;
      }
    }

    #wp-admin-bar-new_draft, #wp-admin-bar-gform-forms {
      display: none !important;
    }

    #mobile-trigger, #main-nav {
      //top: 46px;
    }

    #mobile-menu-container {
      //top: (46px + @logo-mobile-height + (@grid-gutter-width/ 2));
    }

    #wpadminbar {
      position: fixed !important;
    }
  }
}

.store-launch {
  display: none !important;
}


// MOBILE MENU BASE_ANIMATION

#mobile-menu-container {
  transform: rotateX(90deg);
  transform-origin: 50% 0%;

  &.open {
    transform: rotateX(0deg);
  }
}

@media (max-width: @screen-md-min) {
  #main-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 0 0;

    .brand-container {
      order: 1;
      padding: (@grid-gutter-width/4);
    }

    .promotion-container {
      padding: (@grid-gutter-width/4);
      order: 0;
      width: 100%;
      background: @brand-black;
      color: @brand-text-on-dark;
    }

    .top-nav {
      padding: (@grid-gutter-width/4);
      order: 2;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      li a span.menu-text {
        display: none;
      }

      li a {
        padding: 10px 10px;
      }

      li a i {
        margin-right: 0;
      }
    }
  }
}
