
.navbar {
  z-index: 1;
  display: block;
  min-height: 0px;
}

.nav {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  position: relative;
  z-index: 1;

  li {
    z-index: 1;
    display: inline-block;
    width: auto;
    position: relative;

    a {
      display: inline-block;
      width: 100%;
    }

    ul {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;

      li {
        width: 100%;

        a {
          width: 100%;
        }

        ul {
          position: relative;
          width: 100%;
          padding-left: 0em;

          li {
            width: 100%;
            padding-left: 0px;

            a {
              width: 100%;
            }
          }

        }
      }
    }

    a:hover, a:focus, a:active {
      + ul {
        display: block;
      }
    }

    &:hover, &:focus, &:active {
      ul {
        display: block;
      }
    }

  }
}

#main-header {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: (@grid-gutter-width * .25) (@grid-gutter-width * .5);
  @brand-item-width: 250px;
  .promotion-container {
    padding: (@grid-gutter-width/4);
    box-sizing: border-box;
    max-width: calc(100% - (@brand-item-width * 2));
  }
  div.brand-container {
    width: @brand-item-width
  }

  div.promotion-container {
    p {
      display: inline-block;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  div.top-nav {
    width: auto;
    a {
      padding: .5em .5em;
      text-decoration: none;
      color: @brand-black;

      &:hover {
        color: @brand-secondary;
      }

      i {
        margin-right: .5em;

        &:only-child {
          margin-right: 0;
        }

        color: @brand-secondary;
      }
    }
  }
}

@width-item: (@grid-gutter-width * 5);
@negate-width: ((@width-item) * 2);
.main-nav-container {
  width: 100%;
  display: inline-flex;
  display: none;
  &.opened {
    display: inline-flex;
  }
  flex-wrap: wrap;
  align-items: stretch;
  background: @brand-secondary;
  color: @brand-text-on-dark;
  position: relative;
  z-index: 9;

a {
  text-decoration: none;
}
  ul.nav {
    position: initial;

    width: calc(100% - @width-item);
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: stretch;
    z-index: 9;

    a {
      line-height: 1;
    }

    > li {
      position: initial;
      z-index: 10;
      display: inline-flex;
      align-items: center;

      > a {
        text-transform: uppercase;
        color: @brand-text-on-dark;
        padding: 0.25em 0.75em;
        &.menu-toggle {
          padding: .25em 0;
        }
      }

      a.menu-toggle {
        text-align:right;
        width: 1.5em;
      }

      a.menu-toggle-neighbor {
        width: calc(100% - 1.5em);
      }

      > ul {
        border: 1px solid @brand-text-on-dark;
        border-bottom: 1px solid transparent;
        z-index: 11;
        padding: 0;
        //display: none;
        position: absolute;
        //display: flex;
        display: none;
        width: 100%;
        left: 0;
        top: 100%;
        height: auto;
        background: fade(@brand-secondary, 90%);
      }

      li {

      }

      &.normal {
        position: relative;

        &:hover, &:focus, &:active {
          > ul {
            display: block;
          }
        }

        > ul {
          width: (@width-item);

          li {
            &.child {
              border-top: 1px solid @brand-text-on-dark;
            }

            a {
              color: @brand-text-on-dark;
              padding: .5em 1em;
              width: 100%;
              margin-top: .25em;
              margin-bottom: .25em;

            }
          }
        }
      }

      &.wide {
        &:hover, &:focus, &:active {
          > ul {
            display: flex;
          }
        }


        > ul {
          li {
            position: relative;
          }

          li, li ul, li ul ul {
            display: inline-block;
            top: 0;
            left: 0;

            a {
              color: @brand-text-on-dark;
            }
          }

          > li {
            min-height: calc(1.5 * @width-item);
            width: @width-item;
            box-sizing: border-box;

            &.first-item {

              padding: (@grid-gutter-width * .5);
              border-right: 2px solid @brand-text-on-dark;
              &:before {
                position:absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(143deg, fade(@brand-secondary,60%) 0%, fade(@brand-primary,60%) 100%);
                z-index: -1;
              }
              a {
                text-transform: uppercase;
                width: auto;

                border-bottom: .2em solid @brand-primary;
                padding-bottom: .125em;
                font-size: 2em;
                letter-spacing: 0.075em;
                line-height: @headings-line-height;
                font-weight: @headings-font-weight;
                text-align: left;
                padding-left: 0;
                padding-right: 0;
              }
            }

            &.child {
              padding: (@grid-gutter-width * .5);

              img.menu-icon-image {
                max-width: 50%;
                margin-bottom: 1em;
                height: 7em;
                @media(max-width: @screen-xl-min){
                  height: 5em;

                }
              }

              a span {
                text-transform:uppercase;
                width: 100%;
                font-size: 1.25em;
                display: inline-block;
                margin-bottom: .5em;
                font-weight: @headings-font-weight;
              }

              li a {
                font-weight: @font-weight-base;
              }
            }
          }

          li > ul {
            position: relative;
            width: 100%;

            ul {
              width: 100%;
            }

          }

        }

      }

      a.main-nav-toggle {
        width: @width-item;
        color: @brand-text-on-dark;
        background: @brand-primary;
        font-weight: @headings-font-weight;
        text-transform: uppercase;

        padding: 10px 20px;
      }
        ul.main-nav-toggle-child {
          border: 0px solid transparent;
          padding: 0 0 0 0;
          line-height: 0;

          > li.child {
            border: 0px solid transparent;
            margin: 0;

            a.main-nav-toggle {
              margin: 0;
              text-transform: uppercase;
              width: @width-item;
              color: @brand-text-on-dark;
              background: @brand-black;
              font-weight: @headings-font-weight;
              padding: 10px 20px;
            }
          }

        }

    }


  }

  form.navbar-form {
    width: @width-item;
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;

    div.form-group {
      margin-bottom: 0;
      width: 100%;

      input {
        font-weight: @font-weight-base;
        height: 100%;
        border: 1px solid transparent;
        border-left: 1px solid @brand-text-on-dark;
        margin: 0;
        padding-right: 20px;
        background: transparent;
        color: @brand-text-on-dark;

        &::placeholder {
          color: @brand-text-on-dark;
        }

        &:focus {
          background: @brand-primary;
          outline: 0;
          box-shadow: none;
        }
      }

    }

    button {
      padding: 0;

      &:hover, &:focus {
        color: @brand-text-on-dark;
        border: 1px solid transparent;
        outline: 0;
        box-shadow: none;
      }

      background: transparent;
      margin-left: (@grid-gutter-width * -0.5);
      padding-right: (@grid-gutter-width * 0.5);
    }

  }
}

@media(max-width: @screen-xl-min){
  @width-item: 150px;
  #main-header .promotion-container {
    max-width: calc(100% - (@width-item * 2));
    width: 50%;
  }
  .main-nav-container {
    font-size: .875em;
    ul.nav {
      width: calc(100% - @width-item);
      li > a.main-nav-toggle {
        width: @width-item;

      }
      li  ul.main-nav-toggle-child  > li.child  a.main-nav-toggle {
        width: @width-item;
      }
    }
    ul.nav > li.normal > ul {
      width: @width-item;
    }
    form.navbar-form {
      width: @width-item;
    }

  }
  .main-nav-container ul.nav > li.wide > ul > li {
    width: @width-item;
    min-height: (1.5 * @width-item);
    &.first-item {
      padding: (@grid-gutter-width * .25);
    }
    &.child {
      a span {
        font-size: 1em;
      }
      padding: (@grid-gutter-width * .25);
    }
    &.first-item  a {
      font-size: 1.375em;
    }
  }
}
@media(max-width: @screen-lg-min){
  @width-item: 120px;
.promotion-container {
  max-width: calc(100% - (@width-item * 2));
}
  .main-nav-container {
    font-size: .75em;
    ul.nav {
      width: calc(100% - @width-item);
      li > a.main-nav-toggle {
        width: @width-item;

      }
      li  ul.main-nav-toggle-child  > li.child  a.main-nav-toggle {
        width: @width-item;
      }
    }
    ul.nav > li.normal > ul {
      width: @width-item;
    }
    form.navbar-form {
      width: @width-item;
    }

  }
  .main-nav-container ul.nav > li.wide > ul > li {
    width: @width-item;
    min-height: (1.5 * @width-item);

    &.first-item  a {
      font-size: 1.5em;
    }
  }
}
@media(max-width: @screen-md-min){
  #main-header .promotion-container {
    max-width: 100%;
    width: 100%;
  }
  #main-header div.brand-container, #main-header div.top-nav {
    width: 50%;
  }
  #main-header .top-nav {
    text-align:right;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0px;
  }
  .main-nav-container {
    display: none !important;
    &.opened {
      display: none;
    }
  }
}
