#content section .column img {
  max-width :100%;
  width: auto;
  height: auto;
}
img.alignright {
  float:right;
  margin-left:1em;
  display:inline-block;
}
img.alignleft {
  float:left;
  margin-right:1em;
  display:inline-block;
}
@media(max-width:@screen-md-min) {
  img.alignleft,img.alignright {
    &.size-full {
      float:none;
      display:block;
      }
    }
  }

@media(max-width:@screen-sm-min) {
  img.alignleft,img.alignright {
    display:block;
    float: none;
    margin-top:1em;
    margin-bottom:1em;
    max-width:100%;
    height:auto;
  }
}

img.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}



img.text-center { display: inline-block; margin:auto auto; }
