////---- BICON FONT ---------------------------------------------------------
////-------------------------------------------------------------------------
////-------------------------------------------------------------------------


@font-face{
  font-family:"ICO";
  src:url("../fonts/icomoon.eot?#iefix");
  src:url("../fonts/icomoon.eot?#iefix") format("eot"),url("../fonts/icomoon.woff") format("woff"),url("../fonts/icomoon.ttf") format("truetype"),url("../fonts/icomoon.svg") format("svg");
}


////-------------------------------------------------------------------------
////-------------------------------------------------------------------------
@font-face{
  font-family:"DIN Next";
  font-weight: 400;
  font-style:normal;
  src:url("../fonts/SpecialtyFonts/Fonts/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"),url("../fonts/SpecialtyFonts/Fonts/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff");
}
@font-face{
  font-family:"DIN Next";
  font-weight: 400;
  font-style:italic;
  src:url("../fonts/SpecialtyFonts/Fonts/e8258209-d292-4480-9fe3-b30dfa63ea97.woff2") format("woff2"),url("../fonts/SpecialtyFonts/Fonts/c3cbbafb-1878-424d-b2f5-b176d0f9b370.woff") format("woff");
}
@font-face{
  font-family:"DIN Next";
  font-weight: 600;
  font-style:normal;
  src:url("../fonts/SpecialtyFonts/Fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"),url("../fonts/SpecialtyFonts/Fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff");
}
@font-face{
  font-family:"DIN Next";
  font-weight: 600;
  font-style:italic;
  src:url("../fonts/SpecialtyFonts/Fonts/a6eb0ff6-9fe2-47b1-947e-fe6d477fea22.woff2") format("woff2"),url("../fonts/SpecialtyFonts/Fonts/194d1c66-dc36-4c21-90af-3fe62de2fc02.woff") format("woff");
}
