section div.image-card-container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;

  div.link-card {
    width: 25%;
    width: calc(25% - (@grid-gutter-width * .5));
    margin: (@grid-gutter-width * .25);
    @media(max-width: @screen-md-min){
      width: calc(50% - (@grid-gutter-width * .5));
      margin: (@grid-gutter-width * .25);
    }
    @media(max-width: @screen-sm-min){
      width: calc(100% - (@grid-gutter-width * .5));
      margin: (@grid-gutter-width * .25);
    }

    background-size: cover !important;
    background-position: center center !important;

    a {
      background: fade(@brand-black, 40%);
      transition: background @transition-time-global;
      width: 100%;
      height: 100%;
      padding-bottom: 150%;
      position: relative;
      text-align: center;
      display: inline-flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      &:hover {
        background: fade(@brand-secondary, 60%);
      }

      div.card-content {
        position: absolute;
        width: 100%;
        padding: @grid-gutter-width (@grid-gutter-width * .5);
        top: 50%;
        h3 {
          @media(min-width:@screen-md-min) and (max-width: @screen-lg-min){
            font-size: 1.25em;
          }
        }
        text-align: center;
        transform: translateY(-50%);
        @media(max-width:@screen-sm-min){
          //font-size: .875em;
        }
      }

    }
  }
}



.image-brand-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .link-card-brand {
    width: 120px;
    height: 120px;
    margin: 5px;
    padding: 10px;
    background: @brand-text-on-dark;
    a {
      img {
        max-width: auto;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

  }
}
