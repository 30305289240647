#live-area, #content {
  .block {
    padding: (@grid-gutter-width );
    .pt-image.row {
      margin: 0 (-1 * @grid-gutter-width) (@grid-gutter-width ) (-1 * @grid-gutter-width);
      &:first-child {
        margin: (-1 * @grid-gutter-width) (-1 * @grid-gutter-width) (@grid-gutter-width * @paragraph-margin-factor) (-1 * @grid-gutter-width);
      }
    }
  }
}



section.indented-container {
  .container {
    padding: (@grid-gutter-width);
  }
}
section.generic-content {
  .container {
    background : @brand-text-on-dark;
    padding: @grid-gutter-width @grid-gutter-width;
  }
  .row {
    .column {
      padding-left: @grid-gutter-width;
      padding-right: @grid-gutter-width;
      &.col-img {
        padding: 0 0;

      }
    }
  }
}



.side-by-side {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  ~ .side-by-side {
    margin-top: (@grid-gutter-width )
  }
  .col-txt{
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .block {

    }
  }
  .col-img {
    padding: 0 0 0 0;
  }

  @media(max-width: @screen-sm-max){
    .col-txt {
      order: 2;
    }
    .col-img {
      order: 1;
    }
  }

}
#live-area section .column .block {
  width: 100%;
}
section.blocks {
  .container {
    padding: (@grid-gutter-width *.25 );
  }
  .column {
    padding: (@grid-gutter-width *.25 );
  }
}
section.blocks-white {
  .block {
    background: @brand-text-on-dark;
  }
}
section.inner-page-header {

  .row {
    display :flex;
    align-items: stretch;
    flex-wrap: wrap;
    .col-txt,.col-img {
      padding: 0 0 0 0;
    }
    .col-txt {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      background: @brand-text-on-dark;
      .block {
      }
    }
    .col-img {
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
    }
    @media(max-width: @screen-sm-max){
      .col-txt {
        order: 2;
      }
      .col-img {
        order: 1;
      }
    }
  }

  &.full-width {
    .row {
      @media(min-width:@screen-lg-min){
        .col-txt {
          justify-content: center;

          .block {
            max-width: 640px;
          }
        }
        .col-img {
            @media (min-width: @screen-lg-min) {
              max-height: 640px;
              overflow: hidden;
          }
        }
      }
    }
  }
}






#live-area, #content {


section.services-overview   {
  margin-top: (@grid-gutter-width);
  .container {
    .row {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

    }
    .col-overview {
      display: flex;
      align-items: stretch;
      padding: 0 0 0 0;
      .target, .block {
        display: flex;
        align-items: flex-start;
        padding: 0 0 0 0;
        flex-wrap: wrap;
        flex-direction: column;

      }
      .text-object {
        padding: @grid-gutter-width;

      }
      div.pt-ds.filler-image {
        flex-grow: 3;
        position: relative;
        z-index: 1;
        color: @brand-text-on-dark;
        .text-object {
          padding-top: 0;
          padding-bottom: 0;
        }
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .pt-image.image-cover {
        position:absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        &:before{
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background: linear-gradient(143deg, fade(@brand-secondary,60%) 0%, fade(@brand-primary,60%) 100%);
        }
        //margin-left: (@grid-gutter-width * -1);
        //margin-right: (@grid-gutter-width * -1);
        //margin-bottom: (@grid-gutter-width * -1);
        img {
          object-fit: cover;
          object-position: center center;
          max-width: auto;
          height: 100%;
          width: 100%;
        }
      }

    }
    .col-detail {
      display : flex;
      align-items: center;
      flex-wrap: wrap;
      .bg-black;
      .target {

      }
    }
  }


}
}
