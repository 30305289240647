

.bs-flex-fix{
  &:before,&:after{
    display: none !important;
  }
}

.flex-container-mixin(@screen-min,@name) {
  @spec: ~"@{name}";
  // No Wrapping Solutions

  .flex-@{spec}-stretch-nowrap {
    display:block;

    @media (min-width: @screen-min) {
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-center-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-start-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-end-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-end;
      flex-wrap: nowrap;
      .bs-flex-fix();
    }
  }


  .flex-@{spec}-stretch {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-center {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-start {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-end {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-baseline {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      .bs-flex-fix();
    }
  }
  .flex-@{spec}-baseline-last {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: last-baseline;
      flex-wrap: wrap;
      .bs-flex-fix();
    }
  }

  .flex-@{spec}-reset {
    display:block;
    @media (min-width: @screen-min) {
      display: block;
    }
  }

}

.flex-container-mixin(0,~'xs');
.flex-container-mixin(@screen-sm-min,~'sm');
.flex-container-mixin(@screen-md-min,~'md');
.flex-container-mixin(@screen-lg-min,~'lg');

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-space-between {
  justify-content: space-between;
}
.justify-space-evenly {
  justify-content: space-evenly;
}
.justify-space-around {
  justify-content: space-around;
}




@media(max-width: @screen-sm-min) and (min-width: @screen-xs-min){
  .row-md-fifths {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    .bs-flex-fix();
    .column {
      width: 50%;
    }
  }
}
@media(max-width: @screen-md-min) and (min-width: @screen-sm-min){
  .row-md-fifths {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    .bs-flex-fix();
    .column {

      width: 33.3333333%;
    }
  }
}
.fifths-row-mixin(@screen-min,@name) {
  @spec: ~"@{name}";
  // No Wrapping Solutions

  .row-@{spec}-fifths {

    @media (min-width: @screen-min) {
      .column {
        width: 20%;
      }
    }
  }
}
.fifths-row-mixin(0,~'xs');
.fifths-row-mixin(@screen-sm-min,~'sm');
.fifths-row-mixin(@screen-md-min,~'md');
.fifths-row-mixin(@screen-lg-min,~'lg');



